exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-find-an-installer-js": () => import("./../../../src/pages/find-an-installer.js" /* webpackChunkName: "component---src-pages-find-an-installer-js" */),
  "component---src-pages-find-an-installer-thank-you-js": () => import("./../../../src/pages/find-an-installer-thank-you.js" /* webpackChunkName: "component---src-pages-find-an-installer-thank-you-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-heating-and-cooling-js": () => import("./../../../src/pages/heating-and-cooling.js" /* webpackChunkName: "component---src-pages-heating-and-cooling-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insulation-and-sealing-js": () => import("./../../../src/pages/insulation-and-sealing.js" /* webpackChunkName: "component---src-pages-insulation-and-sealing-js" */),
  "component---src-pages-lighting-js": () => import("./../../../src/pages/lighting.js" /* webpackChunkName: "component---src-pages-lighting-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-stoves-js": () => import("./../../../src/pages/stoves.js" /* webpackChunkName: "component---src-pages-stoves-js" */),
  "component---src-pages-subscribe-thank-you-js": () => import("./../../../src/pages/subscribe-thank-you.js" /* webpackChunkName: "component---src-pages-subscribe-thank-you-js" */),
  "component---src-pages-visualizations-coal-history-js": () => import("./../../../src/pages/visualizations/coal-history.js" /* webpackChunkName: "component---src-pages-visualizations-coal-history-js" */),
  "component---src-pages-visualizations-electricity-emissions-by-state-js": () => import("./../../../src/pages/visualizations/electricity-emissions-by-state.js" /* webpackChunkName: "component---src-pages-visualizations-electricity-emissions-by-state-js" */),
  "component---src-pages-visualizations-energy-price-history-js": () => import("./../../../src/pages/visualizations/energy-price-history.js" /* webpackChunkName: "component---src-pages-visualizations-energy-price-history-js" */),
  "component---src-pages-visualizations-scrolly-test-js": () => import("./../../../src/pages/visualizations/scrolly-test.js" /* webpackChunkName: "component---src-pages-visualizations-scrolly-test-js" */),
  "component---src-pages-visualizations-the-efficiency-myth-js": () => import("./../../../src/pages/visualizations/the-efficiency-myth.js" /* webpackChunkName: "component---src-pages-visualizations-the-efficiency-myth-js" */),
  "component---src-pages-visualizations-the-politics-of-banning-natural-gas-js": () => import("./../../../src/pages/visualizations/the-politics-of-banning-natural-gas.js" /* webpackChunkName: "component---src-pages-visualizations-the-politics-of-banning-natural-gas-js" */),
  "component---src-pages-visualizations-vehicle-miles-js": () => import("./../../../src/pages/visualizations/vehicle-miles.js" /* webpackChunkName: "component---src-pages-visualizations-vehicle-miles-js" */),
  "component---src-pages-water-heaters-js": () => import("./../../../src/pages/water-heaters.js" /* webpackChunkName: "component---src-pages-water-heaters-js" */),
  "component---src-templates-author-pages-js": () => import("./../../../src/templates/author-pages.js" /* webpackChunkName: "component---src-templates-author-pages-js" */),
  "component---src-templates-longform-js": () => import("./../../../src/templates/longform.js" /* webpackChunkName: "component---src-templates-longform-js" */),
  "component---src-templates-simple-js": () => import("./../../../src/templates/simple.js" /* webpackChunkName: "component---src-templates-simple-js" */)
}

